import React, {Suspense} from 'react';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import "./styles/_index.scss";

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <Suspense fallback={
      <div className="fixed w-full h-full flex justify-center items-center">
        <div className="loader"/>
      </div>}>
      <App/>
    </Suspense>
  </BrowserRouter>
);

serviceWorkerRegistration.unregister();

