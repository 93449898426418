import {useContext, useEffect, useState} from "react";
import SelectInput from "../inputs/SelectInput";
import {RangeInputContext} from "../Utils";

export default function CardDetail({index, fuel, onCalculationResults}) {
  const [fuelType] = useState(fuel);
  const [calculationResults, setCalculationResults] = useState({});
  const {rangeValues} = useContext(RangeInputContext);

  const fuelCompatibility = {
    // General
    "Type de poids lourd": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Durée en mois": ["PHEV", "BEV", "GNL", "Diesel", "B100", "GNC"],
    "Kilométrage période": ["PHEV", "BEV", "GNL", "Diesel", "B100", "GNC"],
    "kilométrage mensuel": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],

    // Financement
    "Valeur résiduelle": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Loyer": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],

    // Sur amortissement
    "Impact": ["PHEV", "Diesel", "B100", "GNC", "GNL", "BEV"],

    // Carburant
    "Coût mensuel combustible (électrique)": ["PHEV", "BEV"],
    "Coût mensuel combustible (thermique)": ["PHEV", "Diesel", "B100"],
    "Coût mensuel combustible (gaz)": ["GNC", "GNL"],

    // Autres
    "Coût mensuel": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Pneus (€/km)": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Type de poids lourds": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Crédit bail / location": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],

    // Variables
    "Coût mensuel charges variables": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Coût kilométrique charges variables": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],

    // Total
    "Coût mensuel global": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Coût kilométrique global": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],

    // PHEV
    "Coût mensuel combustible globale": ["PHEV"],

    // Multiple utilisation
    "Coût kilométrique": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Au kilomètre": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Coût fixe mensuel": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
  }

  function calculateLeaseCost(rate, financingDuration, acquisitionCost, localAid, nationalAid, residualValue, creditBail) {
    rate = rate / 12;
    let leaseCost;

    if (creditBail) {
      leaseCost = ((((0 - ((-+acquisitionCost) + +localAid + +nationalAid)) * ((1 + +rate) ** +financingDuration) - +residualValue) * rate)) / ((((1 + rate) ** +financingDuration) - 1) * (1 + +rate));
    } else {
      leaseCost = ((((0 - ((-+acquisitionCost) + +localAid + +nationalAid)) * ((1 + +rate) ** +financingDuration) - +residualValue) * rate)) / (((1 + rate) ** +financingDuration) - 1);
    }
    return leaseCost;
  }

  function calculateLeaseCostOthers(rate, financingDuration, acquisitionCost, residualValue, creditBail) {
    rate = rate / 12;
    let leaseCost;

    if (creditBail) {
      leaseCost = ((((0 - (-+acquisitionCost)) * ((1 + +rate) ** +financingDuration) - +residualValue) * rate)) / ((((1 + rate) ** +financingDuration) - 1) * (1 + +rate));
    } else {
      leaseCost = ((((0 - (-+acquisitionCost)) * ((1 + +rate) ** +financingDuration) - +residualValue) * rate)) / (((1 + rate) ** +financingDuration) - 1);
    }
    return leaseCost;
  }


  const calculator = (index) => {
    // General
    let durationInMonth = +rangeValues[index].detentionDuration * 12;
    let kilometerPeriod = +rangeValues[index].kilometersPerYear * (durationInMonth / 12);
    let monthlyKilometer = +rangeValues[index].kilometersPerYear / 12;

    // Ajout de l'appel à calculateLeaseCost
    let rate = rangeValues[index].rate;
    let financingDuration = rangeValues[index].financingDuration;
    let acquisitionCost = rangeValues[index].acquisitionCost;
    let localAid = rangeValues[index].localAid;
    let nationalAid = rangeValues[index].nationalAid;
    let creditBail = rangeValues[index].creditBail;

// Financement
    let residualValue = (+rangeValues[index].residualValue * +rangeValues[index].acquisitionCost) / 100;
    let leaseCost;
    if (fuelType === "PHEV" || fuelType === "Diesel" || fuelType === "B100") {
      leaseCost = calculateLeaseCostOthers(rate, financingDuration, acquisitionCost, residualValue, creditBail);
    } else if (fuelType === "BEV" || fuelType === "GNC" || fuelType === "GNL") {
      leaseCost = calculateLeaseCost(rate, financingDuration, acquisitionCost, localAid, nationalAid, residualValue, creditBail);
    }
    let financialFixedMonthlyCost = (leaseCost * +rangeValues[index].financingDuration + residualValue - +rangeValues[index].resaleValue) / durationInMonth;
    let financialPerKilometer = financialFixedMonthlyCost / monthlyKilometer;
// Amortissement
    let impact;
    if (fuelType === "PHEV" || fuelType === "Diesel") {
      impact = 0;
    } else {
      impact = (+rangeValues[index].acquisitionCost * 0.4) * +rangeValues[index].taxRate;
    }
    let amortizationFixedMonthlyCost;
    if (fuelType === "PHEV") {
      amortizationFixedMonthlyCost = financialFixedMonthlyCost
    } else {
      amortizationFixedMonthlyCost = financialFixedMonthlyCost - (impact / durationInMonth);
    }
    let amortizationPerKilometer;
    if (fuelType === "PHEV") {
      amortizationPerKilometer = financialPerKilometer;
    } else {
      amortizationPerKilometer = amortizationFixedMonthlyCost / monthlyKilometer;
    }
// Carburant
// PHEV
    let monthlyThermalFuelCostPHEV = (((+rangeValues[index].dieselConsumption * (monthlyKilometer * +rangeValues[index].thermalUse)) / 100) * +rangeValues[index].dieselCost) / 100;
    let thermalFuelPerKilometerPHEV = monthlyThermalFuelCostPHEV / monthlyKilometer;
    let monthlyElectricFuelCostPHEV = (((+rangeValues[index].electricConsumption * (monthlyKilometer * +rangeValues[index].electricUse)) / 100) * +rangeValues[index].costPerKwh) / 100;
    let electricFuelPerKilometerPHEV = monthlyElectricFuelCostPHEV / monthlyKilometer;
    let monthlyFuelCostPHEV = monthlyThermalFuelCostPHEV + monthlyElectricFuelCostPHEV;
    let fuelPerKilometerPHEV = thermalFuelPerKilometerPHEV + electricFuelPerKilometerPHEV;
// BEV
    let monthlyElectricFuelCostBEV = ((+rangeValues[index].electricConsumption * monthlyKilometer) / 100) * +rangeValues[index].costPerKwh;
    let electricFuelPerKilometerBEV = monthlyElectricFuelCostBEV / monthlyKilometer;
// GNL / GNC
    let monthlyGazFuelCost = ((+rangeValues[index].gasConsumption * monthlyKilometer) / 100) * +rangeValues[index].gasCost;
    let gazFuelPerKilometer = monthlyGazFuelCost / monthlyKilometer;
// Diesel
    let monthlyThermalFuelCostDiesel = (((monthlyKilometer * +rangeValues[index].dieselConsumption) / 100) * +rangeValues[index].dieselCost) + (((monthlyKilometer * +rangeValues[index].adBlueConsumption * +rangeValues[index].dieselConsumption) / 100) * +rangeValues[index].adBlueCost);
    let thermalFuelPerKilometerDiesel = monthlyThermalFuelCostDiesel / monthlyKilometer;
// B100
    let monthlyThermalFuelCostB100 = ((monthlyKilometer * +rangeValues[index].dieselConsumption) / 100) * +rangeValues[index].dieselCost;
    let thermalFuelPerKilometerB100 = monthlyThermalFuelCostB100 / monthlyKilometer;
// Autres
// PHEV
    let monthlyCostPHEV = ((+rangeValues[index].maintenance * durationInMonth) + (+rangeValues[index].insurance * durationInMonth) + (+rangeValues[index].tiresCostPerKm * kilometerPeriod)) / durationInMonth;
    let kilometerCostPHEV = monthlyCostPHEV / monthlyKilometer;
    let monthlyChargesVariablesCostPHEV = monthlyCostPHEV + monthlyFuelCostPHEV;
    let kilometerChargesVariablesCostPHEV = kilometerCostPHEV + fuelPerKilometerPHEV;
// Autres carburants
    let monthlyCost = (+rangeValues[index].maintenance + +rangeValues[index].insurance + (+rangeValues[index].tiresCostPerKm * monthlyKilometer));
    let kilometerCost = monthlyCost / monthlyKilometer;
// BEV
    let monthlyChargesVariablesCostBEV = (monthlyCost + monthlyElectricFuelCostBEV);
    let kilometerChargesVariablesCostBEV = kilometerCost + electricFuelPerKilometerBEV;
// Diesel
    let monthlyChargesVariablesCostDiesel = (monthlyCost + monthlyThermalFuelCostDiesel);
    let kilometerChargesVariablesCostDiesel = kilometerCost + thermalFuelPerKilometerDiesel;
// B100
    let monthlyChargesVariablesCostB100 = monthlyCost + monthlyThermalFuelCostB100;
    let kilometerChargesVariablesCostB100 = kilometerCost + thermalFuelPerKilometerB100;
// GNC / GNL
    let monthlyChargesVariablesCostGaz = monthlyCost + monthlyGazFuelCost;
    let kilometerChargesVariablesCostGaz = kilometerCost + gazFuelPerKilometer;
// Total
// PHEV
    let monthlyGlobalCostPHEV = monthlyChargesVariablesCostPHEV + amortizationFixedMonthlyCost;
    let kilometerGlobalCostPHEV = kilometerChargesVariablesCostPHEV + amortizationPerKilometer;
// BEV
    let monthlyGlobalCostBEV = monthlyChargesVariablesCostBEV + amortizationFixedMonthlyCost;
    let kilometerGlobalCostBEV = kilometerChargesVariablesCostBEV + amortizationPerKilometer;
// Diesel
    let monthlyGlobalCostDiesel = monthlyChargesVariablesCostDiesel + amortizationFixedMonthlyCost;
    let kilometerGlobalCostDiesel = kilometerChargesVariablesCostDiesel + amortizationPerKilometer;
// B100
    let monthlyGlobalCostB100 = monthlyChargesVariablesCostB100 + amortizationFixedMonthlyCost;
    let kilometerGlobalCostB100 = kilometerChargesVariablesCostB100 + amortizationPerKilometer;
// GNC / GNL
    let monthlyGlobalCostGaz = monthlyChargesVariablesCostGaz + amortizationFixedMonthlyCost;
    let kilometerGlobalCostGaz = kilometerChargesVariablesCostGaz + amortizationPerKilometer;

    // Vérifier si les valeurs sont des nombres


    // Return les valeurs
    return {
      durationInMonth: durationInMonth === 0 ? "0" : parseFloat(durationInMonth).toFixed(1),
      kilometerPeriod: kilometerPeriod === 0 ? "0" : parseFloat(kilometerPeriod).toFixed(1),
      monthlyKilometer: monthlyKilometer === 0 ? "0" : parseFloat(monthlyKilometer).toFixed(1),
      residualValue: residualValue === 0 ? "0" : parseFloat(residualValue).toFixed(1),
      leaseCost: leaseCost === 0 ? "0" : parseFloat(leaseCost).toFixed(1),
      financialFixedMonthlyCost: financialFixedMonthlyCost === 0 || financialFixedMonthlyCost === Infinity ? "0" : parseFloat(financialFixedMonthlyCost).toFixed(1),
      financialPerKilometer: financialPerKilometer === 0 || financialPerKilometer === Infinity ? "0" : parseFloat(financialPerKilometer).toFixed(1),
      impact: impact === 0 ? "0" : parseFloat(impact).toFixed(1),
      amortizationFixedMonthlyCost: amortizationFixedMonthlyCost === 0 || amortizationFixedMonthlyCost === Infinity ? "0" : parseFloat(amortizationFixedMonthlyCost).toFixed(1),
      amortizationPerKilometer: amortizationPerKilometer === 0 || amortizationPerKilometer === Infinity ? "0" : parseFloat(amortizationPerKilometer).toFixed(1),
      monthlyThermalFuelCostPHEV: monthlyThermalFuelCostPHEV === 0 ? "0" : parseFloat(monthlyThermalFuelCostPHEV).toFixed(1),
      thermalFuelPerKilometerPHEV: thermalFuelPerKilometerPHEV === 0 ? "0" : parseFloat(thermalFuelPerKilometerPHEV).toFixed(1),
      monthlyElectricFuelCostPHEV: monthlyElectricFuelCostPHEV === 0 ? "0" : parseFloat(monthlyElectricFuelCostPHEV).toFixed(1),
      electricFuelPerKilometerPHEV: electricFuelPerKilometerPHEV === 0 ? "0" : parseFloat(electricFuelPerKilometerPHEV).toFixed(1),
      monthlyFuelCostPHEV: monthlyFuelCostPHEV === 0 ? "0" : parseFloat(monthlyFuelCostPHEV).toFixed(1),
      fuelPerKilometerPHEV: fuelPerKilometerPHEV === 0 ? "0" : parseFloat(fuelPerKilometerPHEV).toFixed(1),
      monthlyElectricFuelCostBEV: monthlyElectricFuelCostBEV === 0 ? "0" : parseFloat(monthlyElectricFuelCostBEV).toFixed(1),
      electricFuelPerKilometerBEV: electricFuelPerKilometerBEV === 0 ? "0" : parseFloat(electricFuelPerKilometerBEV).toFixed(1),
      monthlyGazFuelCost: monthlyGazFuelCost === 0 ? "0" : parseFloat(monthlyGazFuelCost).toFixed(1),
      gazFuelPerKilometer: gazFuelPerKilometer === 0 ? "0" : parseFloat(gazFuelPerKilometer).toFixed(1),
      monthlyThermalFuelCostDiesel: monthlyThermalFuelCostDiesel === 0 ? "0" : parseFloat(monthlyThermalFuelCostDiesel).toFixed(1),
      thermalFuelPerKilometerDiesel: thermalFuelPerKilometerDiesel === 0 ? "0" : parseFloat(thermalFuelPerKilometerDiesel).toFixed(1),
      monthlyThermalFuelCostB100: monthlyThermalFuelCostB100 === 0 ? "0" : parseFloat(monthlyThermalFuelCostB100).toFixed(1),
      thermalFuelPerKilometerB100: thermalFuelPerKilometerB100 === 0 ? "0" : parseFloat(thermalFuelPerKilometerB100).toFixed(1),
      monthlyCostPHEV: monthlyCostPHEV === 0 ? "0" : parseFloat(monthlyCostPHEV).toFixed(1),
      kilometerCostPHEV: kilometerCostPHEV === 0 ? "0" : parseFloat(kilometerCostPHEV).toFixed(1),
      monthlyChargesVariablesCostPHEV: monthlyChargesVariablesCostPHEV === 0 ? "0" : parseFloat(monthlyChargesVariablesCostPHEV).toFixed(1),
      kilometerChargesVariablesCostPHEV: kilometerChargesVariablesCostPHEV === 0 ? "0" : parseFloat(kilometerChargesVariablesCostPHEV).toFixed(1),
      monthlyCost: monthlyCost === 0 ? "0" : parseFloat(monthlyCost).toFixed(1),
      kilometerCost: kilometerCost === 0 ? "0" : parseFloat(kilometerCost).toFixed(1),
      monthlyChargesVariablesCostBEV: monthlyChargesVariablesCostBEV === 0 ? "0" : parseFloat(monthlyChargesVariablesCostBEV).toFixed(1),
      kilometerChargesVariablesCostBEV: kilometerChargesVariablesCostBEV === 0 ? "0" : parseFloat(kilometerChargesVariablesCostBEV).toFixed(1),
      monthlyChargesVariablesCostDiesel: monthlyChargesVariablesCostDiesel === 0 ? "0" : parseFloat(monthlyChargesVariablesCostDiesel).toFixed(1),
      kilometerChargesVariablesCostDiesel: kilometerChargesVariablesCostDiesel === 0 ? "0" : parseFloat(kilometerChargesVariablesCostDiesel).toFixed(1),
      monthlyChargesVariablesCostB100: monthlyChargesVariablesCostB100 === 0 ? "0" : parseFloat(monthlyChargesVariablesCostB100).toFixed(1),
      kilometerChargesVariablesCostB100: kilometerChargesVariablesCostB100 === 0 ? "0" : parseFloat(kilometerChargesVariablesCostB100).toFixed(1),
      monthlyChargesVariablesCostGaz: monthlyChargesVariablesCostGaz === 0 ? "0" : parseFloat(monthlyChargesVariablesCostGaz).toFixed(1),
      kilometerChargesVariablesCostGaz: kilometerChargesVariablesCostGaz === 0 ? "0" : parseFloat(kilometerChargesVariablesCostGaz).toFixed(1),
      monthlyGlobalCostPHEV: monthlyGlobalCostPHEV === 0 ? "0" : parseFloat(monthlyGlobalCostPHEV).toFixed(1),
      kilometerGlobalCostPHEV: kilometerGlobalCostPHEV === 0 ? "0" : parseFloat(kilometerGlobalCostPHEV).toFixed(1),
      monthlyGlobalCostBEV: monthlyGlobalCostBEV === 0 ? "0" : parseFloat(monthlyGlobalCostBEV).toFixed(1),
      kilometerGlobalCostBEV: kilometerGlobalCostBEV === 0 ? "0" : parseFloat(kilometerGlobalCostBEV).toFixed(1),
      monthlyGlobalCostDiesel: monthlyGlobalCostDiesel === 0 ? "0" : parseFloat(monthlyGlobalCostDiesel).toFixed(1),
      kilometerGlobalCostDiesel: kilometerGlobalCostDiesel === 0 ? "0" : parseFloat(kilometerGlobalCostDiesel).toFixed(1),
      monthlyGlobalCostB100: monthlyGlobalCostB100 === 0 ? "0" : parseFloat(monthlyGlobalCostB100).toFixed(1),
      kilometerGlobalCostB100: kilometerGlobalCostB100 === 0 ? "0" : parseFloat(kilometerGlobalCostB100).toFixed(1),
      monthlyGlobalCostGaz: monthlyGlobalCostGaz === 0 ? "0" : parseFloat(monthlyGlobalCostGaz).toFixed(1),
      kilometerGlobalCostGaz: kilometerGlobalCostGaz === 0 ? "0" : parseFloat(kilometerGlobalCostGaz).toFixed(1)
    };
  }

  const isNumber = (value) => {
    for (let key in value) {
      if (isNaN(value[key])) {
        value[key] = 0;
      }
    }
  }

  useEffect(() => {
    const results = calculator(index);
    isNumber(results)
    setCalculationResults(results);
    onCalculationResults(index, results);
  }, [index, rangeValues]);


  return (
    <div className="card">
      <SelectInput index={index} value={fuelType} disabled={true}/>
      <div className="inputs-container">
        <div className="flex flex-col gap-[2rem] px-[1rem] py-[0.8125rem] rounded-lg bg-slate-200">
          {fuelCompatibility["Type de poids lourd"].includes(fuelType) &&
            <div className="flex justify-between gap-5">
              <p className="text-card">Type de poids lourd</p>
              <p className="value-card">{rangeValues[index].vehicleType}</p>
            </div>
          }
          <div>
            {fuelCompatibility["Durée en mois"].includes(fuelType) &&
              <div className="flex justify-between gap-5">
                <p className="text-card">Durée en mois</p>
                <p className="value-card">{calculationResults.durationInMonth}</p>
              </div>
            }
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            {fuelCompatibility["Kilométrage période"].includes(fuelType) &&
              <div className="flex justify-between gap-5">
                <p className="text-card">Kilométrage période</p>
                <p className="value-card">{calculationResults.kilometerPeriod}</p>
              </div>
            }
            {fuelCompatibility["kilométrage mensuel"].includes(fuelType) &&
              <div className="flex justify-between gap-5">
                <p className="text-card">kilométrage mensuel</p>
                <p className="value-card">{calculationResults.monthlyKilometer}</p>
              </div>
            }
          </div>
        </div>

        <div className="flex flex-col gap-[0.5rem] rounded-lg bg-slate-200">
          <div className="flex flex-col gap-[2rem]">
            <div className="flex flex-col gap-[0.5rem] px-[1rem] pt-[0.8125rem]">
              <p className="text-card text-red uppercase">Financement</p>

              {fuelCompatibility["Valeur résiduelle"].includes(fuelType) &&
                <div className="flex justify-between gap-5">
                  <p className="text-card">Valeur résiduelle</p>
                  <p className="value-card">{calculationResults.residualValue}</p>
                </div>
              }
              {fuelCompatibility["Loyer"].includes(fuelType) &&
                <div className="flex justify-between gap-5">
                  <p className="text-card">Loyer</p>
                  <p className="value-card">{calculationResults.leaseCost}</p>
                </div>
              }
              {fuelCompatibility["Coût fixe mensuel"].includes(fuelType) &&
                <div className="flex justify-between gap-5">
                  <p className="text-card">Coût fixe mensuel</p>
                  <p className="value-card">{calculationResults.financialFixedMonthlyCost}</p>
                </div>
              }
              {fuelCompatibility["Au kilomètre"].includes(fuelType) &&
                <div className="flex justify-between gap-5">
                  <p className="text-card">Au kilomètre</p>
                  <p className="value-card">{calculationResults.financialPerKilometer}</p>
                </div>
              }
            </div>

            <div className="flex flex-col gap-[0.5rem]">
              <div className="px-[1rem] flex flex-col gap-[0.5rem]">
                <p className="text-card text-red uppercase">Sur amortissement</p>
                {
                  fuelCompatibility["Impact"].includes(fuelType) &&
                  <div className="flex justify-between gap-5">
                    <p className="text-card">Impact</p>
                    <p className="value-card">{calculationResults.impact}</p>
                  </div>
                }
              </div>
              <div className="flex flex-col gap-[0.5rem] bg-[#80AF81] rounded-lg px-[1rem] py-[0.8125rem]">
                {
                  fuelCompatibility["Coût fixe mensuel"].includes(fuelType) &&
                  <div className="flex justify-between gap-5">
                    <p className="text-card text-total">Coût fixe mensuel</p>
                    <p className="value-card text-total">{calculationResults.amortizationFixedMonthlyCost}</p>
                  </div>
                }
                {
                  fuelCompatibility["Au kilomètre"].includes(fuelType) && <div className="flex justify-between gap-5">
                    <p className="text-card text-total">Au kilomètre</p>
                    <p className="value-card text-total">{calculationResults.amortizationPerKilometer}</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[0.5rem] px-[1rem] py-[0.8125rem] rounded-lg bg-slate-200">
          <p className="text-card text-red uppercase">Carburant</p>
          <div className="flex flex-col gap-[2rem]">
            {
              fuelCompatibility["Coût mensuel combustible (thermique)"].includes(fuelType) && fuelCompatibility["Au kilomètre"].includes(fuelType) &&
              <div className="flex flex-col gap-[0.5rem]">
                <div className="flex justify-between gap-5">
                  <p className="text-card">Coût mensuel combustible (thermique)</p>
                  <p
                    className="value-card">{fuelType === "PHEV" ? calculationResults.monthlyThermalFuelCostPHEV : fuelType === "Diesel" ? calculationResults.monthlyThermalFuelCostDiesel : fuelType === "B100" ? calculationResults.monthlyThermalFuelCostB100 : null}</p>
                </div>
                <div className="flex justify-between gap-5">
                  <p className="text-card">Au kilomètre</p>
                  <p
                    className="value-card">{fuelType === "PHEV" ? calculationResults.thermalFuelPerKilometerPHEV : fuelType === "Diesel" ? calculationResults.thermalFuelPerKilometerDiesel : fuelType === "B100" ? calculationResults.thermalFuelPerKilometerB100 : null}</p>
                </div>
              </div>
            }

            {
              fuelCompatibility["Coût mensuel combustible (gaz)"].includes(fuelType) && fuelCompatibility["Au kilomètre"].includes(fuelType) &&
              <div className="flex flex-col gap-[0.5rem]">
                <div className="flex justify-between gap-5">
                  <p className="text-card">Coût mensuel combustible (gaz)</p>
                  <p className="value-card">{calculationResults.monthlyGazFuelCost}</p>
                </div>
                <div className="flex justify-between gap-5">
                  <p className="text-card">Au kilomètre</p>
                  <p className="value-card">{calculationResults.gazFuelPerKilometer}</p>
                </div>
              </div>
            }

            {
              fuelCompatibility["Coût mensuel combustible (électrique)"].includes(fuelType) && fuelCompatibility["Coût kilométrique"].includes(fuelType) &&
              <div className="flex flex-col gap-[0.5rem]">
                <div className="flex justify-between gap-5">
                  <p className="text-card">Coût mensuel combustible (électrique)</p>
                  <p
                    className="value-card">{fuelType === "PHEV" ? calculationResults.monthlyElectricFuelCostPHEV : calculationResults.monthlyElectricFuelCostBEV}</p>
                </div>
                <div className="flex justify-between gap-5">
                  <p className="text-card">Coût kilométrique</p>
                  <p
                    className="value-card">{fuelType === "PHEV" ? calculationResults.electricFuelPerKilometerPHEV : calculationResults.electricFuelPerKilometerBEV}</p>
                </div>
              </div>
            }

            {
              fuelCompatibility["Coût mensuel combustible globale"].includes(fuelType) &&
              <div className="flex flex-col gap-[0.5rem]">
                <div className="flex justify-between gap-5">
                  <p className="text-card">Coût mensuel combustible globale</p>
                  <p className="value-card">{calculationResults.monthlyFuelCostPHEV}</p>
                </div>
                <div className="flex justify-between gap-5">
                  <p className="text-card">Coût Kilométrique global</p>
                  <p className="value-card">{calculationResults.fuelPerKilometerPHEV}</p>
                </div>
              </div>
            }

          </div>
        </div>

        <div className="flex flex-col gap-[0.5rem] px-[1rem] py-[0.8125rem] rounded-lg bg-slate-200">
          {
            fuelCompatibility["Coût mensuel"].includes(fuelType) &&
            <div className="flex justify-between gap-5">
              <p className="text-card">Coût mensuel</p>
              <p
                className="value-card">{fuelType === "PHEV" ? calculationResults.monthlyCostPHEV : calculationResults.monthlyCost}</p>
            </div>
          }
          {
            fuelCompatibility["Coût kilométrique"].includes(fuelType) &&
            <div className="flex justify-between gap-5">
              <p className="text-card">Coût kilométrique</p>
              <p
                className="value-card">{fuelType === "PHEV" ? calculationResults.kilometerCostPHEV : calculationResults.kilometerCost}</p>
            </div>
          }
        </div>

        <div className="flex flex-col gap-[0.5rem] px-[1rem] py-[0.8125rem] rounded-lg bg-[#80AF81]">
          {
            fuelCompatibility["Coût mensuel charges variables"].includes(fuelType) &&
            <div className="flex justify-between gap-5">
              <p className="text-card text-total">Coût mensuel charges variables</p>
              <p
                className="value-card text-white">{fuelType === "PHEV" ? calculationResults.monthlyChargesVariablesCostPHEV : fuelType === "BEV" ? calculationResults.monthlyChargesVariablesCostBEV : fuelType === "Diesel" ? calculationResults.monthlyChargesVariablesCostDiesel : fuelType === "B100" ? calculationResults.monthlyChargesVariablesCostB100 : calculationResults.monthlyChargesVariablesCostGaz}</p>
            </div>
          }
          {
            fuelCompatibility["Coût kilométrique charges variables"].includes(fuelType) &&
            <div className="flex justify-between gap-5">
              <p className="text-card text-total">Coût kilométrique charges variables</p>
              <p
                className="value-card text-white">{fuelType === "PHEV" ? calculationResults.kilometerChargesVariablesCostPHEV : fuelType === "BEV" ? calculationResults.kilometerChargesVariablesCostBEV : fuelType === "Diesel" ? calculationResults.kilometerChargesVariablesCostDiesel : fuelType === "B100" ? calculationResults.kilometerChargesVariablesCostB100 : calculationResults.kilometerChargesVariablesCostGaz}</p>
            </div>
          }
        </div>

        <div className="total-container">
          {
            fuelCompatibility["Coût mensuel global"].includes(fuelType) &&
            <div className="flex justify-between gap-5">
              <p className="text-card text-total">Coût Mensuel Global</p>
              <p
                className="value-card text-total">{fuelType === "PHEV" ? calculationResults.monthlyGlobalCostPHEV : fuelType === "BEV" ? calculationResults.monthlyGlobalCostBEV : fuelType === "Diesel" ? calculationResults.monthlyGlobalCostDiesel : fuelType === "B100" ? calculationResults.monthlyGlobalCostB100 : calculationResults.monthlyGlobalCostGaz}</p>
            </div>
          }
          {
            fuelCompatibility["Coût kilométrique global"].includes(fuelType) &&
            <div className="flex justify-between gap-5">
              <p className="text-card text-total">Coût Kilométrique Global</p>
              <p
                className="value-card text-total">{fuelType === "PHEV" ? calculationResults.kilometerGlobalCostPHEV : fuelType === "BEV" ? calculationResults.kilometerGlobalCostBEV : fuelType === "Diesel" ? calculationResults.kilometerGlobalCostDiesel : fuelType === "B100" ? calculationResults.kilometerGlobalCostB100 : calculationResults.kilometerGlobalCostGaz}</p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

