import {useContext, useEffect} from "react";
import {RangeInputContext} from "../Utils";

export default function ToggleInput({index, value}) {
  const {rangeValues, setRangeValues} = useContext(RangeInputContext);

  const handleChange = (ev) => {
    value = ev.target.checked;
    setRangeValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = {...newValues[index], "creditBail": value};
      return newValues;
    });
  };

  useEffect(() => {
    if (rangeValues && rangeValues[index]?.creditBail === undefined) {
      setRangeValues(prevValues => {
        const newValues = [...prevValues];
        newValues[index] = {...newValues[index], "creditBail": value};
        return newValues;
      });
    }
  }, []);

  return (
    <div className="flex flex-col gap-2.5">
      <label htmlFor="" className="text-card">Crédit bail / location</label>
      <div className="flex gap-2">
        <p className="value-card">Non</p>
        <input
          type="checkbox"
          className="toggle rounded-xl"
          checked={value}
          onChange={handleChange}
        />
        <p className="value-card">Oui</p>
      </div>
    </div>
  )
}