import CardData from "./cards/CardData";
import CardBtn from "./button/CardBtn";
import {useEffect, useState} from "react";

export default function TCOGetData({fuels, setFuels, setUndefinedFuels, result}) {
  const [cardValue, setCardValue] = useState(false);
  const [cardError, setCardError] = useState(false);

  const addCard = () => {
    if (fuels.length > 1 && fuels[0] !== undefined) {
      setCardValue(true);
      setCardError(false);
    } else {
      setCardError(true);
    }
  };

  const handleFuelChange = (index, fuel) => {
    // Mettez à jour la valeur de fuel pour le SelectInput à l'index donné
    if (fuel !== "Sélectionner un carburant") {
      const newFuels = [...fuels];
      newFuels[index] = fuel;
      setFuels(newFuels);
    }
  };

  useEffect(() => {
    if (fuels.length === 3) {
      setCardValue(true);
    }
    if (fuels.length === 2) {
      setCardError(false);
    }
    if (fuels.length > 1) {
      setUndefinedFuels(false)
    }
  }, [fuels]);

  return (
    <>
      <h1 className="hidden">TCO Calculator</h1>
      <div className="cards-data-container">
        <CardData index={0} onFuelChange={(fuel) => handleFuelChange(0, fuel)} fuels={fuels} setFuels={setFuels}
                  cardValue={cardValue} result={result}/>
        <CardData index={1} onFuelChange={(fuel) => handleFuelChange(1, fuel)} fuels={fuels} setFuels={setFuels}
                  cardValue={cardValue} result={result}/>
        <div className="flex flex-col gap-5">
          {cardError ? <p className="text-red text-card">Veuillez choisir deux profils avant de sélectionner un
            troisième</p> : null}
          {!cardValue ? <CardBtn onClick={addCard} value="Ajouter un profil"/> : null}
          {cardValue ?
            <CardData index={2} closeCard={() => setCardValue(false)} onFuelChange={(fuel) => handleFuelChange(2, fuel)}
                      fuels={fuels} setFuels={setFuels} cardValue={cardValue} result={result}/> : null}
        </div>
      </div>
    </>
  );
};