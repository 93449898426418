import {Chart} from "react-google-charts";
import {useContext, useEffect} from "react";
import {RangeInputContext} from "../Utils";

export default function CardGraphic({onCalculationResults, fuels}) {
  const {rangeValues} = useContext(RangeInputContext);

  const handleChargesVariablesCost = (fuel, index) => {
    if (fuel === "PHEV") {
      return +onCalculationResults[index].monthlyChargesVariablesCostPHEV;
    } else if (fuel === "BEV") {
      return +onCalculationResults[index].monthlyChargesVariablesCostBEV;
    } else if (fuel === "GNL" || fuel === "GNC") {
      return +onCalculationResults[index].monthlyChargesVariablesCostGaz;
    } else if (fuel === "Diesel") {
      return +onCalculationResults[index].monthlyChargesVariablesCostDiesel;
    } else if (fuel === "B100") {
      return +onCalculationResults[index].monthlyChargesVariablesCostB100;
    }
  };

  const handleKilometerChargesVariablesCost = (fuel, index) => {
    if (fuel === "PHEV") {
      return +onCalculationResults[index].kilometerChargesVariablesCostPHEV;
    } else if (fuel === "BEV") {
      return +onCalculationResults[index].kilometerChargesVariablesCostBEV;
    } else if (fuel === "GNL" || fuel === "GNC") {
      return +onCalculationResults[index].kilometerChargesVariablesCostGaz;
    } else if (fuel === "Diesel") {
      return +onCalculationResults[index].kilometerChargesVariablesCostDiesel;
    } else if (fuel === "B100") {
      return +onCalculationResults[index].kilometerChargesVariablesCostB100;
    }
  }

  useEffect(() => {
    handleChargesVariablesCost(fuels[0], 0);
    handleChargesVariablesCost(fuels[1], 1);
    handleChargesVariablesCost(fuels[2], 2);
    handleKilometerChargesVariablesCost(fuels[0], 0);
    handleKilometerChargesVariablesCost(fuels[1], 1);
    handleKilometerChargesVariablesCost(fuels[2], 2);
  }, [onCalculationResults]);

  const dataMonthlyTCO = [
    [
      "Carburant",
      "Coût mensuel charges variables",
    ],
    [
      `${fuels[0]} : ${rangeValues[0]?.vehicleType !== undefined ? rangeValues[0].vehicleType : ''}`,
      handleChargesVariablesCost(fuels[0], 0)
    ],
    [
      `${fuels[1]} : ${rangeValues[1]?.vehicleType !== undefined ? rangeValues[1].vehicleType : ''}`,
      handleChargesVariablesCost(fuels[1], 1)
    ],
  ];

  if (fuels[2]) {
    dataMonthlyTCO.push([
      `${fuels[2]} : ${rangeValues[2]?.vehicleType !== undefined ? rangeValues[2].vehicleType : ''}`,
      handleChargesVariablesCost(fuels[2], 2)
    ]);
  }


  const optionsMonthlyTCO = {
    seriesType: "bars",
    legend: {position: "bottom"},
    series: {
      0: {type: "bars", color: '#CEB888'},
    },
    vAxis: {
      minValue: 0,  // Ajoutez cette ligne pour commencer l'axe des y à 0
    },
  };

  const dataKilometerTCO = [
    [
      "Carburant",
      "Coût kilomètre charges variables",
    ],
    [`${fuels[0]} : ${rangeValues[0]?.vehicleType !== undefined ? rangeValues[0].vehicleType : ''}`, handleKilometerChargesVariablesCost(fuels[0], 0)],
    [`${fuels[1]} : ${rangeValues[1]?.vehicleType !== undefined ? rangeValues[1].vehicleType : ''}`, handleKilometerChargesVariablesCost(fuels[1], 1)],
  ];

  if (fuels[2]) {
    dataKilometerTCO.push([`${fuels[2]} : ${rangeValues[2]?.vehicleType !== undefined ? rangeValues[2].vehicleType : ''}`, handleKilometerChargesVariablesCost(fuels[2], 2)]);
  }

  const optionsKilometerTCO = {
    seriesType: "bars",
    legend: {position: "bottom"},
    series: {
      0: {type: "bars", color: '#CEB888'},
    },
    vAxis: {
      minValue: 0,  // Ajoutez cette ligne pour commencer l'axe des y à 0
    },
  }

  const dataMonthlyFixedTCO = [
    [
      "Carburant",
      "Coût fixe mensuel",
    ],
    [`${fuels[0]} : ${rangeValues[0]?.vehicleType !== undefined ? rangeValues[0].vehicleType : ''}`, +onCalculationResults[0]?.amortizationFixedMonthlyCost],
    [`${fuels[1]} : ${rangeValues[1]?.vehicleType !== undefined ? rangeValues[1].vehicleType : ''}`, +onCalculationResults[1]?.amortizationFixedMonthlyCost],
  ];

  if (fuels[2]) {
    dataMonthlyFixedTCO.push([`${fuels[2]} : ${rangeValues[2]?.vehicleType !== undefined ? rangeValues[2].vehicleType : ''}`, +onCalculationResults[2]?.amortizationFixedMonthlyCost]);
  }

  const optionsMonthlyFixedTCO = {
    seriesType: "bars",
    legend: {position: "bottom"},
    series: {
      0: {type: "bars", color: '#E35205'},
    },
    vAxis: {
      minValue: 0,  // Ajoutez cette ligne pour commencer l'axe des y à 0
    },
  };

  const dataPerKilometerTCO = [
    [
      "Carburant",
      "Au kilomètre",
    ],
    [`${fuels[0]} : ${rangeValues[0]?.vehicleType !== undefined ? rangeValues[0].vehicleType : ''}`, +onCalculationResults[0]?.amortizationPerKilometer],
    [`${fuels[1]} : ${rangeValues[1]?.vehicleType !== undefined ? rangeValues[1].vehicleType : ''}`, +onCalculationResults[1]?.amortizationPerKilometer],
  ];

  if (fuels[2]) {
    dataPerKilometerTCO.push([`${fuels[2]} : ${rangeValues[2]?.vehicleType !== undefined ? rangeValues[2].vehicleType : ''}`, +onCalculationResults[2]?.amortizationPerKilometer]);
  }

  const optionsPerKilometerTCO = {
    seriesType: "bars",
    legend: {position: "bottom"},
    series: {
      0: {type: "bars", color: '#E35205'},
    },
    vAxis: {
      minValue: 0,  // Ajoutez cette ligne pour commencer l'axe des y à 0
    },
  };

  return (
    <div className=" flex flex-col sm:grid grid-cols-2 grid-rows-2 gap-4 xl:gap-8">
      <div className="card-graphic">
        <h2 className="chart-title pr-[2.5rem]">Comparatif coût mensuel des charges variables en euros</h2>
        <Chart
          chartType="ComboChart"
          data={dataMonthlyTCO}
          options={optionsMonthlyTCO}
          className="chart"
        />
      </div>
      <div className="card-graphic">
        <h2 className="chart-title pr-[2.5rem]">Comparatif coût kilomètrique des charges variables en euros</h2>
        <Chart
          chartType="ComboChart"
          data={dataKilometerTCO}
          options={optionsKilometerTCO}
          className="chart"
        />
      </div>
      <div className="card-graphic">
        <h2 className="chart-title pr-[2.5rem]">Comparatif coût fixe mensuel en euros</h2>
        <Chart
          chartType="ComboChart"
          data={dataMonthlyFixedTCO}
          options={optionsMonthlyFixedTCO}
          className="chart"
        />
      </div>
      <div className="card-graphic">
        <h2 className="chart-title pr-[2.5rem]">Comparatif coût au kilomètre en euros</h2>
        <Chart
          chartType="ComboChart"
          data={dataPerKilometerTCO}
          options={optionsPerKilometerTCO}
          className="chart"
        />
      </div>
    </div>
  )
}