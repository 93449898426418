export default function DuplicateBtn({ onClick, value }) {
  return (
    <div
      className={`duplicate-btn-container rounded-lg ${
        value === "Dupliquer les valeurs"
          ? "mx-auto" + "mt-8"
          : "mb-10"
      } +
    " w-fit`}
    >
      <button className="duplicate-btn" onClick={onClick}>
        <p className="py-2 px-4 ScaniaSansHeadlineBold">{value}</p>
      </button>
    </div>
  );
}
