import { useEffect, useState, useRef } from "react";
import CardDetail from "./cards/CardDetail";
import CardGraphic from "./cards/CardGraphic";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import DuplicateBtn from "./button/DuplicateBtn";

export default function TCOShowResult({
  fuels,
  resultOption,
  handleCalculationResults,
  allCalculationResults,
}) {
  const [width, setWidth] = useState(window.innerWidth);
  const contentRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const exportPdf = () => {
    const content = contentRef.current;

    if (content) {
      domtoimage
        .toPng(content, {
          bgcolor: "#ffffff", // Fond blanc pour les images
          filter: (node) => node.nodeName !== "BUTTON", // Exclure les boutons du rendu
        })
        .then(function (dataUrl) {
          const pdf = new jsPDF("p", "mm", "a4");
          const imgProps = pdf.getImageProperties(dataUrl);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
          if (resultOption) {
            pdf.save("résultats-détails.pdf");
          } else {
            pdf.save("résultats-graphiques.pdf");
          }
        })
        .catch(function (error) {
          console.error("Erreur lors de la génération du PDF", error);
        });
    }
  };

  return (
    <>
      <h1 className="hidden">TCO Détails</h1>
      {resultOption ? (
        <div
          ref={contentRef}
          className={
            fuels[2]
              ? "cards-details-container cards-details-container-two"
              : !fuels[2] && width < 1440
              ? "cards-details-container cards-details-container-one"
              : "cards-details-container cards-details-container-two"
          }
        >
          <CardDetail
            fuel={fuels[0]}
            index={0}
            onCalculationResults={(index, results) =>
              handleCalculationResults(0, results)
            }
          />
          <CardDetail
            fuel={fuels[1]}
            index={1}
            onCalculationResults={(index, results) =>
              handleCalculationResults(1, results)
            }
          />
          {fuels[2] ? (
            <CardDetail
              fuel={fuels[2]}
              index={2}
              onCalculationResults={(index, results) =>
                handleCalculationResults(2, results)
              }
            />
          ) : width >= 1440 ? (
            <div className="empty-card w-full h-full"></div>
          ) : null}
        </div>
      ) : (
        <div className="cards-graphic-container" ref={contentRef}>
          <CardGraphic
            fuels={fuels}
            onCalculationResults={allCalculationResults}
          />
        </div>
      )}
      <div className="flex justify-end">
        <DuplicateBtn
          onClick={exportPdf}
          value="Exporter en pdf"
          className="mt-0"
        />
      </div>
    </>
  );
}
