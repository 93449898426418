import React, { useContext, useEffect, useState } from 'react';
import { RangeInputContext } from "../Utils";

export default function RangeInput({ min, max, initial, label, step, name, index }) {
  const [value, setValue] = useState(initial);
  const { rangeValues, setRangeValues, fuelType } = useContext(RangeInputContext); // Added fuelType context

  const handleChange = (ev) => {
    let newValue = ev.target.value;
    if (newValue < min) {
      newValue = min;
    } else if (newValue > max) {
      newValue = max;
    }
    setValue(newValue);
    setRangeValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = { ...newValues[index], [name]: newValue };
      return newValues;
    });
  };

  const handleBlur = (ev) => {
    if (ev.target.value === "" || ev.target.value === null) {
      setValue(min);
    }
  };

  // useEffect(() => {
  //   setValue(initial);
  //   setRangeValues(prevValues => {
  //     const newValues = [...prevValues];
  //     newValues[index] = { ...newValues[index], [name]: initial };
  //     return newValues;
  //   });
  // }, [initial, fuelType]); // Listen to fuelType changes

  useEffect(() => {
    setValue(initial); // Update local state when initial prop changes
  }, [initial]);

  return (
    <div className="flex flex-col gap-2.5">
      <label className="text-card">{label}</label>
      <div className="flex gap-3 items-center">
        <p className="value-card">{min}</p>
        <input
          className="range range-sm rounded-2xl rounded-thumb"
          step={step}
          type="range"
          min={min}
          max={max}
          name={name}
          value={value} // use value instead of initial
          onChange={handleChange}
          required
        />
        <input
          type="number"
          className="w-20 value-card"
          value={value} // use value instead of initial
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
      </div>
    </div>
  );
};
