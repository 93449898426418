import SelectInput from "../inputs/SelectInput";
import RangeInput from "../inputs/RangeInput";
import ToggleInput from "../inputs/ToggleInput";
import TextInput from "../inputs/TextInput";
import {useContext, useEffect, useState} from "react";
import CardBtn from "../button/CardBtn";
import {RangeInputContext} from "../Utils";
import DuplicateBtn from "../button/DuplicateBtn";

export default function CardData({index, closeCard, onFuelChange, fuels, setFuels, cardValue, result}) {
  const [fuelType, setFuelType] = useState('Sélectionner un carburant');
  const {rangeValues, setRangeValues} = useContext(RangeInputContext);

  const fuelCompatibility = {
    "Kilomètres (km/an)": ["PHEV", "BEV", "GNL", "Diesel", "B100", "GNC"],
    "Coût d'acquisition": ["PHEV", "BEV", "GNL", "Diesel", "B100", "GNC"],
    "Aides nationales": ["GNL", "GNC", "BEV"],
    "Aides locales": ["GNL", "GNC", "BEV"],
    "Durée de détention (an)": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Taux (%)": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Valeur résiduelle (%)": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Durée du financement (mois)": ["PHEV", "Diesel", "B100", "GNC", "GNL", "BEV"],
    "Valeur de revente": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Utilisation thermique (%)": ["PHEV"],
    "Consommation gasoil (100kms)": ["PHEV", "Diesel", "B100"],
    "Coût gasoil (€/L)": ["PHEV", "Diesel", "B100"],
    "Consommation AD BLUE (%/conso gasoil)": ["Diesel", "B100", "PHEV"],
    "Coût AD BLUE (€/L)": ["Diesel", "B100", "PHEV"],
    "Utilisation électrique (%)": ["PHEV"],
    "Consommation électrique (100kms)": ["PHEV", "BEV"],
    "Coût (Kwh)": ["PHEV", "BEV"],
    "Consommation gaz (100kms)": ["GNC", "GNL"],
    "Coût gaz (€/L)": ["GNC", "GNL"],
    "Maintenance (€/mois)": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Assurances (€/mois)": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Pneus (€/km)": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Taux d'imposition": ["BEV", "B100", "GNC", "GNL"],
    "Type de poids lourds": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
    "Crédit bail / location": ["PHEV", "BEV", "Diesel", "B100", "GNC", "GNL"],
  };

  const removeCard = () => {
    const newFuels = fuels.filter((fuel, i) => i !== 2);
    setFuels(newFuels);
    setRangeValues(prevValues => {
      const newValues = [...prevValues];
      newValues[2] = {};
      return newValues;
    });
    closeCard();
  }

  const handleFuelChange = (fuel, index) => {
    setFuelType(fuel);
    onFuelChange(fuel);
  }

  const handleClick = (setIndex) => {
    if (rangeValues.length >= 1 && fuels.length >= 1 && !cardValue) {
      // Mettre à jour l'état en créant une nouvelle copie de rangeValues
      setRangeValues([rangeValues[0], rangeValues[0]]);
      // Mettre à jour fuels en dupliquant la première valeur
      setFuels([fuels[0], fuels[0]]);
      if (rangeValues[index]?.fuel) {
        setFuelType(rangeValues[index].fuel);
      }
    } else if (rangeValues.length >= 2 && fuels.length >= 2 && cardValue) {
      // Mettre à jour l'état en créant une nouvelle copie de rangeValues
      setRangeValues([rangeValues[0], rangeValues[0], rangeValues[0]]);
      // Mettre à jour fuels en dupliquant la première valeur
      setFuels([fuels[0], fuels[0], fuels[0]]);
      if (rangeValues[index]?.fuel) {
        setFuelType(rangeValues[index].fuel);
      }
    }
  };

  console.log(rangeValues);

  useEffect(() => {
    if (rangeValues[index]) {
      // Reset the `initial` values for all range inputs when `fuelType` changes
      setRangeValues(prevValues => {
        const newValues = [...prevValues];
        // If the rangeValues for this index is undefined, initialize it
        if (!newValues[index]) newValues[index] = {};

        // Define default values based on `fuelType`
        const defaultValues = {
          kilometersPerYear: rangeValues[index]?.kilometersPerYear ? rangeValues[index]?.kilometersPerYear : "80000",
          acquisitionCost: rangeValues[index]?.acquisitionCost ? rangeValues[index]?.acquisitionCost : "0",
          nationalAid: rangeValues[index]?.nationalAid ? rangeValues[index]?.nationalAid : "0",
          localAid: rangeValues[index]?.localAid ? rangeValues[index]?.localAid : "0",
          detentionDuration: rangeValues[index]?.detentionDuration ? rangeValues[index]?.detentionDuration : "0",
          creditBail: rangeValues[index]?.creditBail ? rangeValues[index]?.creditBail : false,
          rate: rangeValues[index]?.rate ? rangeValues[index]?.rate : "5.5",
          residualValue: rangeValues[index]?.residualValue ? rangeValues[index]?.residualValue : "1",
          financingDuration: rangeValues[index]?.financingDuration ? rangeValues[index]?.financingDuration : fuelType === "BEV" ? "72" : "60",
          resaleValue: rangeValues[index]?.resaleValue ? rangeValues[index]?.resaleValue : "0",
          thermalUse: rangeValues[index]?.thermalUse ? rangeValues[index]?.thermalUse : "0",
          dieselConsumption: rangeValues[index]?.dieselConsumption ? rangeValues[index]?.dieselConsumption : "0",
          dieselCost: rangeValues[index]?.dieselCost ? rangeValues[index]?.dieselCost : "1.45",
          adBlueConsumption: rangeValues[index]?.adBlueConsumption ? rangeValues[index]?.adBlueConsumption : "0",
          adBlueCost: rangeValues[index]?.adBlueCost ? rangeValues[index]?.adBlueCost : "0.60",
          electricUse: rangeValues[index]?.electricUse ? rangeValues[index]?.electricUse : "0",
          electricConsumption: rangeValues[index]?.electricConsumption ? rangeValues[index]?.electricConsumption : "0",
          costPerKwh: rangeValues[index]?.costPerKwh ? rangeValues[index]?.costPerKwh : "0.2",
          gasConsumption: rangeValues[index]?.gasConsumption ? rangeValues[index]?.gasConsumption : "0",
          gasCost: rangeValues[index]?.gasCost ? rangeValues[index]?.gasCost : fuelType === "GNL" ? "1.29" : "1.32",
          maintenance: rangeValues[index]?.maintenance ? rangeValues[index]?.maintenance : "0",
          insurance: rangeValues[index]?.insurance ? rangeValues[index]?.insurance : "0",
          tiresCostPerKm: rangeValues[index]?.tiresCostPerKm ? rangeValues[index]?.tiresCostPerKm : "0",
          taxRate: rangeValues[index]?.taxRate ? rangeValues[index]?.taxRate : "25",
        };

        // Update the `rangeValues` with the default values based on `fuelType`
        Object.keys(defaultValues).forEach(key => {
          newValues[index][key] = defaultValues[key];
        });

        // Ensure `fuel` is always set to the current `fuelType`
        newValues[index].fuel = fuelType;

        return newValues;
      });

      // // Optionally, you can also reset the local state `fuelType` if needed
      // setFuelType(fuelType);
    }
  }, [fuelType]); // No need for `initial` in the dependency array

  useEffect(() => {
    if (rangeValues[index]?.fuel) {
      setFuelType(fuels[index]);
    }
  }, [rangeValues]);


  return (
    <div className="card">
      <div className={index === 2 && "card-data-index-two"}>
        <div className={index === 2 && "select-input-index-two"}>
          <SelectInput onFuelChange={handleFuelChange} index={index}
                       value={rangeValues[index]?.fuel ? rangeValues[index]?.fuel : fuelType} fuels={fuels}/>
        </div>
        {index === 2 ? <CardBtn onClick={() => removeCard()}/> : null}
      </div>
      <div className="inputs-container">
        {fuelCompatibility["Type de poids lourds"].includes(fuelType) &&
          <TextInput label="Type de poids lourds" index={index}
                     value={rangeValues[index]?.vehicleType ? rangeValues[index]?.vehicleType : ''}/>}

        {fuelCompatibility["Kilomètres (km/an)"].includes(fuelType) &&
          <RangeInput min={0} max={300000}
                      initial={rangeValues[index].kilometersPerYear ? rangeValues[index].kilometersPerYear : "80000"}
                      label="Kilomètres (km/an)" step={100} name="kilometersPerYear"
                      index={index}/>}

        {fuelCompatibility["Coût d'acquisition"].includes(fuelType) &&
          <RangeInput min={0} max={fuelType === "BEV" ? 500000 : 300000}
                      initial={rangeValues[index].acquisitionCost ? rangeValues[index].acquisitionCost : "0"}
                      label="Coût d'acquisition" step={100} name="acquisitionCost"
                      index={index}/>}

        {fuelCompatibility["Aides nationales"].includes(fuelType) &&
          <RangeInput min={0} max={300000}
                      initial={rangeValues[index].nationalAid ? rangeValues[index].nationalAid : "0"}
                      label="Aides nationales" step={100} name="nationalAid"
                      index={index}/>}

        {fuelCompatibility["Aides locales"].includes(fuelType) &&
          <RangeInput min={0} max={300000} initial={rangeValues[index].localAid ? rangeValues[index].localAid : "0"}
                      label="Aides locales" step={100} name="localAid"
                      index={index}/>}

        {fuelCompatibility["Durée de détention (an)"].includes(fuelType) &&
          <RangeInput min={0} max={50}
                      initial={rangeValues[index].detentionDuration ? rangeValues[index].detentionDuration : "0"}
                      label="Durée de détention (an)" step={2}
                      name="detentionDuration" index={index}/>}

        {fuelCompatibility["Crédit bail / location"].includes(fuelType) &&
          <ToggleInput index={index} value={rangeValues[index].creditBail ? rangeValues[index].creditBail : false}/>}

        {fuelCompatibility["Taux (%)"].includes(fuelType) &&
          <RangeInput min={0} max={10} initial={rangeValues[index].rate ? rangeValues[index].rate : "5.5"}
                      label="Taux (%)" name="rate" index={index}/>}

        {fuelCompatibility["Valeur résiduelle (%)"].includes(fuelType) &&
          <RangeInput min={0} max={!rangeValues[index]?.creditBail ? 10 : 50}
                      initial={rangeValues[index].residualValue ? rangeValues[index].residualValue : "1"}
                      label="Valeur résiduelle (%)" name="residualValue"
                      index={index}/>}

        {fuelCompatibility["Durée du financement (mois)"].includes(fuelType) &&
          <RangeInput min={0} max={120}
                      initial={rangeValues[index].financingDuration ? rangeValues[index].financingDuration : fuelType === "BEV" ? "72" : "60"}
                      label="Durée du financement (mois)" name="financingDuration"
                      index={index}/>}

        {fuelCompatibility["Valeur de revente"].includes(fuelType) &&
          <RangeInput min={0} max={250000}
                      initial={rangeValues[index].resaleValue ? rangeValues[index].resaleValue : "0"}
                      label="Valeur de revente" name="resaleValue" index={index}/>}

        {fuelCompatibility["Utilisation thermique (%)"].includes(fuelType) &&
          <RangeInput min={0} max={100} initial={rangeValues[index].thermalUse ? rangeValues[index].thermalUse : "0"}
                      label="Utilisation thermique (%)" name="thermalUse"
                      index={index}/>}

        {fuelCompatibility["Consommation gasoil (100kms)"].includes(fuelType) &&
          <RangeInput min={0} max={120}
                      initial={rangeValues[index].dieselConsumption ? rangeValues[index].dieselConsumption : "0"}
                      label="Consommation gasoil (100kms)" name="dieselConsumption"
                      index={index}/>}

        {fuelCompatibility["Coût gasoil (€/L)"].includes(fuelType) &&
          <RangeInput min={0} max={2} initial={rangeValues[index].dieselCost ? rangeValues[index].dieselCost : "1.45"}
                      label="Coût gasoil (€/L)" name="dieselCost" index={index} step={0.2}/>}

        {fuelCompatibility["Consommation AD BLUE (%/conso gasoil)"].includes(fuelType) &&
          <RangeInput min={0} max={120}
                      initial={rangeValues[index].adBlueConsumption ? rangeValues[index].adBlueConsumption : "0"}
                      label="Consommation AD BLUE (%/conso gasoil)"
                      name="adBlueConsumption" index={index}/>}

        {fuelCompatibility["Coût AD BLUE (€/L)"].includes(fuelType) &&
          <RangeInput min={0} max={2} initial={rangeValues[index].adBlueCost ? rangeValues[index].adBlueCost : "0.60"}
                      label="Coût AD BLUE (€/L)" name="adBlueCost" index={index} step={0.2}/>}

        {fuelCompatibility["Utilisation électrique (%)"].includes(fuelType) &&
          <RangeInput min={0} max={100} initial={rangeValues[index].electricUse ? rangeValues[index].electricUse : "0"}
                      label="Utilisation électrique (%)" name="electricUse"
                      index={index}/>}

        {fuelCompatibility["Consommation électrique (100kms)"].includes(fuelType) &&
          <RangeInput min={0} max={120}
                      initial={rangeValues[index].electricConsumption ? rangeValues[index].electricConsumption : "0"}
                      label="Consommation électrique (100kms)"
                      name="electricConsumption" index={index}/>}

        {fuelCompatibility["Coût (Kwh)"].includes(fuelType) &&
          <RangeInput min={0} max={1} initial={rangeValues[index].costPerKwh ? rangeValues[index].costPerKwh : "0.2"}
                      label="Coût (Kwh)" name="costPerKwh" index={index} step={0.1}/>}

        {fuelCompatibility["Consommation gaz (100kms)"].includes(fuelType) &&
          <RangeInput min={0} max={120}
                      initial={rangeValues[index].gasConsumption ? rangeValues[index].gasConsumption : "0"}
                      label="Consommation gaz (100kms)" name="gasConsumption"
                      index={index}/>}

        {fuelCompatibility["Coût gaz (€/L)"].includes(fuelType) &&
          <RangeInput min={0} max={2}
                      initial={rangeValues[index].gasCost ? rangeValues[index].gasCost : fuelType === "GNL" ? "1.29" : "1.32"}
                      label="Coût gaz (€/L)" name="gasCost" index={index} step={0.2}/>}

        {fuelCompatibility["Maintenance (€/mois)"].includes(fuelType) &&
          <RangeInput min={0} max={120} initial={rangeValues[index].maintenance ? rangeValues[index].maintenance : "0"}
                      label="Maintenance (€/mois)" name="maintenance" index={index}/>}

        {fuelCompatibility["Assurances (€/mois)"].includes(fuelType) &&
          <RangeInput min={0} max={500} initial={rangeValues[index].insurance ? rangeValues[index].insurance : "0"}
                      label="Assurances (€/mois)" name="insurance" index={index}/>}

        {fuelCompatibility["Pneus (€/km)"].includes(fuelType) &&
          <RangeInput min={0} max={120}
                      initial={rangeValues[index].tiresCostPerKm ? rangeValues[index].tiresCostPerKm : "0"}
                      label="Pneus (€/km)" name="tiresCostPerKm" index={index}/>}

        {fuelCompatibility["Taux d'imposition"].includes(fuelType) &&
          <RangeInput min={0} max={40} initial={rangeValues[index].taxRate ? rangeValues[index].taxRate : "25"}
                      label="Taux d'imposition" name="taxRate" index={index}/>}

        {fuels.length >= 1 && index === 0 && (
          <div className="flex justify-center">
            <DuplicateBtn onClick={handleClick} value="Dupliquer les valeurs"/>
          </div>
        )}
      </div>
    </div>
  )
};

