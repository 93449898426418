import {NavLink} from "react-router-dom";


export default function Navbar() {
  return (
    <div className="navbar navbar-container">
      <div className="pr-3">
        <NavLink to="/">
          <img src="/img/scania_wordmark_blue_rgb 2.png" alt="home"/>
        </NavLink>
      </div>
      <ul className="">
        <li className="calculator-container">
          <NavLink to="/tco" className="calculator-link ScaniaSansHeadlineBold">
            TCO Calculator
          </NavLink>
        </li>
      </ul>
      <div className="ml-auto w-8">
        <img src="/img/scania_griffon.png" alt="Logo Scania"/>
      </div>
    </div>
  );
}