import TCOCalculator from "../pages/public/TCOCalculator";
import Landing from "../pages/public/Landing";

export const MainRoutes = [
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/tco",
    element: <TCOCalculator />,
  },
];