import MainBtn from '../../components/button/MainBtn';
import { useNavigate } from 'react-router-dom';
import {RangeInputContext} from "../../components/Utils";
import {useContext} from "react";

export default function Landing() {
  const navigate = useNavigate();
  const {setRangeValues} = useContext(RangeInputContext);

  const handleClick = () => {
    navigate('/tco');
    setRangeValues([])
  };

  return (
    <div className="p-5">
      <div className="landing-container">
        <div className="landing-content-container">
          <div className="landing-logo-container">
            <img className="logo-scania" src="/img/logo&scania.png" alt="Logo Scania"/>
          </div>
          <div className="landing-txt-container">
            <p className="primary-txt ScaniaSansHeadlineBold">Bienvenue sur notre calculateur de coût total de possession (TCO) Scania !</p>
            <p className="secondary-txt">Chez Scania, nous comprenons l'importance de maximiser l'efficacité et la rentabilité de votre flotte.
              C'est pourquoi nous avons développé cet outil innovant pour vous aider à évaluer de manière précise et
              détaillée les coûts associés à l'acquisition et à l'exploitation de nos véhicules.</p>
            <p className="secondary-txt">Merci de votre confiance en Scania et bonne utilisation de notre calculateur TCO !</p>
            <p className="secondary-txt">L'équipe Scania</p>
          </div>
          <div className="landing-btn-container">
            <MainBtn value="Commencez maintenant" onClick={handleClick}/>
          </div>
        </div>
        <div className="landing-img-container">
          <img src="/img/green-truck.png" alt="" className="truck-img"/>
        </div>
      </div>
    </div>
  )
};