import React, {useContext, useEffect, useState} from 'react';
import {RangeInputContext} from "../Utils";

export default function SelectInput({onFuelChange, index, value, disabled, fuels, setFuels}) {
  const [selectValue, setSelectValue] = useState("Sélectionner un carburant");
  const {rangeValues, setRangeValues} = useContext(RangeInputContext);

  const handleChange = (ev) => {
    const newValue = ev.target.value;

    // Mettre à jour la valeur
    setSelectValue(newValue);
    onFuelChange(newValue);

    // Réinitialiser rangeValues à l'index spécifique et définir le nouveau carburant
    setRangeValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = {...newValues[index], fuel: newValue, creditBail: false};
      return newValues;
    });
  };

  useEffect(() => {
    setSelectValue(value); // Mettez à jour selectValue chaque fois que value change
  }, [value]);

  return (
    <div className="justify-center flex">
      <select key={value} value={selectValue} onChange={handleChange}
              className="rounded-lg select select-bordered select-sm select-input value-card" name={selectValue}
              disabled={disabled}>
        {selectValue === "Sélectionner un carburant" &&
          <option value="Sélectionner un carburant" className="value-card">Sélectionner un carburant</option>}
        <option value="PHEV" className="value-card">PHEV</option>
        <option value="BEV" className="value-card">BEV</option>
        <option value="GNL" className="value-card">GNL</option>
        <option value="GNC" className="value-card">GNC</option>
        <option value="Diesel" className="value-card">Diesel</option>
        <option value="B100" className="value-card">B100</option>
      </select>
    </div>
  );
};