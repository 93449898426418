export default function CardBtn({onClick, value}) {
  return (
    <div>
      {value === "Ajouter un profil" ?
        <div>
          <button className="border-2 border-dashed rounded-lg uppercase add-card-btn"
                  onClick={onClick}>
            <div className="flex flex-col items-center gap-4 text-card">
              <img src="/img/cross-add.png" alt="plus" width="25"/>
              {value}
            </div>
          </button>
        </div>
        : <button className="bin-btn" onClick={onClick}>
          <img src="/img/ph_trash.png" alt="trash-can"/></button>}
    </div>
  )
}