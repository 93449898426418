import React, {useState} from 'react';
import Routes from './routes';
import Navbar from "./components/Navbar";
import {FuelTypeContext, RangeInputContext} from './components/Utils';
import {useLocation} from 'react-router-dom';


export default function App() {
  const [fuelTypeValue, setFuelTypeValue] = useState('');
  const [rangeValues, setRangeValues] = useState([]);
  const location = useLocation();

  return (
    <div className="main-content-container">
      <RangeInputContext.Provider value={{rangeValues, setRangeValues}}>
        <FuelTypeContext.Provider value={{fuelTypeValue, setFuelTypeValue}}>
          {location.pathname !== "/" && <Navbar/>}
          <Routes/>
        </FuelTypeContext.Provider>
      </RangeInputContext.Provider>
    </div>
  );
}
