import MainBtn from "../../components/button/MainBtn";
import TCOShowResult from "../../components/TCOShowResult";
import TCOGetData from "../../components/TCOGetData";
import {useContext, useEffect, useState} from "react";
import {RangeInputContext} from "../../components/Utils";

export default function TCOCalculator({index}) {
  const [result, setResult] = useState(false);
  const [fuels, setFuels] = useState([]);
  const [resultOption, setResultOption] = useState(true);
  const {rangeValues} = useContext(RangeInputContext);
  const [undefinedFuels, setUndefinedFuels] = useState(false);
  const [allCalculationResults, setAllCalculationResults] = useState([]);

  const handleClick = (ev) => {
    if (fuels.length > 1 && fuels[0] !== undefined) {
      if (result) {
        setResult(false);
        setResultOption(true);
        if (fuels.length === 3) {
          setFuels([fuels[0], fuels[1], fuels[2]]);
        } else {
          setFuels([fuels[0], fuels[1]]);
        }
      } else if (rangeValues[0] !== undefined && rangeValues.length > 1) {
        setResult(true);
        setUndefinedFuels(false);
      }
    } else {
      setUndefinedFuels(true);
    }
  };

  const handleResult = (e) => {
    if (resultOption && e.target.innerText === "GRAPHIQUES") {
      setResultOption(false);
    } else if (!resultOption && e.target.innerText === "DÉTAILS") {
      setResultOption(true);
    }
  };

  const handleCalculationResults = (index, results) => {
    setAllCalculationResults(prevState => {
      const newState = [...prevState];
      newState[index] = results;
      return newState;
    });
  };

  // useEffect(() => {
  //   if (result) {
  //     setResultOption(false);
  //   } else {
  //     setResultOption(true);
  //   }
  // }, [result]);

  return (
    <>
      <div className="cards-content-container">
        {result &&
          <div className="options-container">
            <div
              className={resultOption ? "focused-option" : "unfocused-option"}
              onClick={handleResult}>
              <p className="py-2 px-4 ScaniaSansHeadlineBold">Détails</p>
            </div>
            <div
              className={!resultOption ? "focused-option" : "unfocused-option"}
              onClick={handleResult}>
              <p className="py-2 px-4 ScaniaSansHeadlineBold">Graphiques</p>
            </div>
          </div>
        }
        {result ? <TCOShowResult fuels={fuels} setFuels={setFuels} resultOption={resultOption} index={index}
                                 allCalculationResults={allCalculationResults}
                                 handleCalculationResults={handleCalculationResults}/> :
          <TCOGetData fuels={fuels} setFuels={setFuels} setUndefinedFuels={setUndefinedFuels} result={result}/>}
        {undefinedFuels && <p className="text-card text-red pb-8">Veuillez sélectionner au moins deux carburants</p>}
        <div className="pb-12">
          {result ? <MainBtn value="Modifier la simulation" onClick={handleClick} name="modification"/> :
            <MainBtn value="Voir les résultats" onClick={handleClick}/>}
        </div>
      </div>
    </>
  );
}