import {useContext, useState} from "react";
import {RangeInputContext} from "../Utils";

export default function TextInput({index, label, value}) {
  const {setRangeValues} = useContext(RangeInputContext);

  function handleChange(ev) {
    value = ev.target.value;
    setRangeValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = { ...newValues[index], "vehicleType": value };
      return newValues;
    });
  }

  return (
        <div className="text-input-container">
          <label htmlFor="" className="text-card">{label}</label>
          <input
            type="text"
            className="input input-bordered input-sm rounded-lg text-input value-card"
            value={value}
            onChange={handleChange}
            />
        </div>
    )
}