import {cloneElement} from "react";
import {useLocation, useRoutes} from "react-router-dom";
import {MainRoutes} from "./MainRoutes";
// import {AdminRoutes} from "./AdminRoutes";

export default function Routes() {
  const allRoutes = [...MainRoutes/*, ...AdminRoutes*/];
  const element = useRoutes(allRoutes);

  const location = useLocation();

  if (!element) return null;

  return cloneElement(element, {key: location.pathname});
}